<style lang="scss" scoped>
	.h-cdkDialog{
		.h-cdkDialog-content{
			z-index: 1;
			background: #22201E;
			width: calc(100%);
			position: absolute;
			bottom: 0;
			.h-content {
				padding: 0 0.25rem;
				text-align: center;
				color: #fff;
				font-weight: 500;
				font-size: 0.28rem;
				color: #FFFFFF;
				.tip-img {
					width: 0.88rem;
					margin: 0.3rem 0 0.1rem;
				}
			}
			.h-input{
				margin-top: 0.25rem;
				height: 2.11rem;
				background: rgba(255,255,255,0.08);
				border: 0.01rem solid rgba(255,255,255,0.1);
				font-size: 0.24rem;
				padding: 0.13rem;
			}
			.tip {
				margin: 0.13rem 0 0.44rem;
				font-weight: 400;
				font-size: 0.24rem;
				color: #FFAD00;
				text-align: left;
			}
			
			>footer{
				margin-top: 0.64rem;
				margin: 0.64rem 0.25rem 0.3rem;
				gap:0.36rem;
			}
		}
		
	}
	
	
</style>

<template>
	<h-dialog class="h-cdkDialog" :conf="conf" v-loading="loading" customContent>
		<div class="h-cdkDialog-content n-dialog-content">
			<div class="h-title">
				<div>CDK 兑换</div>
				<img src="@/assets/img/h52/close2.png" @click="cancel()"/>
			</div>
			<div class="h-content">
				<textarea class="h-input" v-model="redbagCode" placeholder="请输入CDK" />
				<!-- <p class="tip">加官方QQ群：654057875，抢红包</p> -->
				<!-- <footer class="h-dialog-footer"><button @click="sub">领取</button></footer> -->
				<footer class="h-dialog-footer">
					<button class="cancel" @click="cancel()">{{ conf.cancelTxt }}</button>
					<button class="confirm" @click="sub()">{{ conf.confirmTxt }}</button>
				</footer>
			</div>
		</div>
	</h-dialog>
	
	
</template>

<script>
	import tool from '@/components/conf/tool.js';
	export const defConf = ()=>({
		show:false,
		cancelTxt: '取消',
		confirmTxt: '确定',
	});
	export default{
		name: 'h-cdk-dialog',
		props: {
			conf:{
				type: Object,
				default:defConf
			},
		},
		watch:{
			conf:{
				immediate:true,
				handler(conf,last){
					if(conf !== last) this.mergeConf(conf,defConf());
				}
			}
		},
		data(){
			return{
				loading:false,
				redbagCode: '',
			}
		},
		computed:{
			isLogin(){
				return this.$store.state.token
			},
			userInfo(){
				return this.$store.state.userInfo || {}
			}
		},
		mounted(){
		},
		methods:{
			cancel() {
				this.conf.show = false
			},
			async sub(){
				const {redbagCode} = this;
				if(!redbagCode) return this.$hMsg('请输入正确的CDK',0)
				const res = await this._req(this.$api.cdkUser({redbagCode}))
				if(!res) return;
				this.$hMsg('领取成功~');
				this.redbagCode = '';
				this.$store.dispatch('getInfo')
			},
			...tool({ keyMap:{ mergeConf:1} }),
		}
		
		
	}
	
	
</script>
